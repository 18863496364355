const eollCaseSet = {
  details: {
    id: "eoll",
    title: "EOLL",
    subTitle: "2-Look OLL 1",
    mask: "oell", // confusing, but deal with it
    view: "plan",
    numCases: 3,
  },
  cases: [
    {
      id: "20d58519-226b-4331-b346-545aad5d41a1",
      name: "L shape",
      algs: ["f R U R' U' f'"],
      group: "",
      scrambles: [
        "U' L2 U' L2 U L R' F' L2 F L R",
        "F2 R2 F L' B L' F' B' R2 F L2 F",
        "D' R2 F2 U' F2 D R2 B L' B2 L B",
        "U2 F2 R2 F L2 B L B' L F' R2 F2",
        "U2 R' U2 R U2 B U2 B' U' R' U' R",
        "U2 B L2 F2 D F D' L2 B' L F L'",
        "F' L U L U' L' U F' B L2 F2 B'",
        "F R' B' R2 F B' L' D2 L F2 B2 U2",
        "U2 R2 B2 L2 F' D2 F L2 B R' B R'",
        "R' F2 R' B2 L R F L' F R' B2 R2",
        "B F' L2 F U2 R' F2 L F2 R U2 B'",
        "R B U B2 U' R' U F R2 B R2 F'",
        "L F2 R2 D2 R D2 F' R F2 L' F U",
        "U2 F' U2 F U R U2 B U2 B' U' R'",
        "L' U2 L2 U L2 U B L B' L U2 L'",
        "L' B2 R2 B' L B2 R2 F' U2 F B U2",
        "B' R B2 L' B D2 R D2 R2 B2 L U2",
        "B2 R F R' F' B R' F R2 F' R2 B",
        "F2 D' L2 D F2 L R B U2 B' L' R'",
        "F' L2 B2 L2 F2 R' B2 R' B2 R B2 F'",
      ],
    },
    {
      id: "4ab6538f-771f-4004-abbf-ca0c3bc3ac09",
      name: "Line",
      algs: ["F R U R' U' F'"],
      group: "",
      scrambles: [
        "U2 R U R2 F' U' F R2 U' R2 U2 R",
        "U2 F' U2 F2 U R' D R' D' R2 U' F'",
        "F2 L2 B L2 F L' B2 U' B U' L F",
        "U2 L' U2 L U F2 D B' R B D' F2",
        "L' R B2 R' U' B U B' U' B' U L",
        "B2 R2 B' U L U' L B R2 B' L2 B'",
        "R' L' B' U2 B R L F2 D R2 D' F2",
        "U B' U' R' U R2 B R' U2 R' U2 R",
        "B D2 U' F2 U L F2 D2 B2 R U' B",
        "U2 R' U2 R2 B2 D B D' B R2 U2 R",
        "F' B' R' F2 R F' B2 U' F2 U F2 B'",
        "F U2 F' U2 F' U' L F L' F' U F",
        "R' D2 U L2 U' B' U2 L2 D2 F' U R'",
        "F' B' R' U2 R F B L2 D F2 D' L2",
        "U2 F U2 F' U2 F' L F2 U F' U' L'",
        "F U R' U' F' U F2 R F2 L' U2 L",
        "F' U2 F U2 F R' F2 U' F U R U",
        "U2 B U2 B2 R2 D' R' D R' B2 U2 B'",
        "R U2 R' U2 R' F R2 U R' U' F' U",
        "B' U B L U' L' B' U' B U2 B' U2 B",
      ],
    },
    {
      id: "0cde1875-8598-459d-99f6-3fe033fcfea9",
      name: "Dot",
      algs: ["F R U R' U' F' f R U R' U' f'"],
      group: "",
      scrambles: [
        "U2 B' R2 F R F' U R B L U' L'",
        "U2 R B2 L2 D2 R F' R' D2 L2 B2 R'",
        "U2 R2 B2 L' B2 R' B L2 U2 L' B' R'",
        "F2 R2 B' R2 F' R B2 U2 B' R' F' U'",
        "U2 F U' F2 D' B L B' D F2 U F'",
        "U' R' U R2 D L' B' L D' R2 U' R",
        "U2 R B2 L2 D2 R F R' D2 L2 B2 R'",
        "R B2 R2 U2 R B R' U2 R2 B2 R' U'",
        "U2 R U' R2 D' L F L' D R2 U R'",
        "U2 F2 L2 B L2 F L' B2 U2 B L F",
        "F' L' U B' U B L F R U2 R' U2",
        "F R2 F2 U2 F R' F' U2 F2 R2 F' U'",
        "L' B' U R' U R B L F U2 F' U2",
        "U2 R2 F2 L F2 R F' L2 U2 L F R",
        "U2 R' F2 R2 U2 R' F' R U2 R2 F2 R",
        "U2 F' L2 F2 U2 F' L F U2 F2 L2 F",
        "F R B U2 B2 R B R2 F' U2 F U2 F'",
        "R B2 R' U2 R B' U' L U' L' R B2 R2",
        "B U2 B' R2 U2 B' R B U2 R' B' R' B",
        "R' F2 L D' L D' R' B' R D2 L2 F2 R",
      ],
    },
  ],
};
export default eollCaseSet;
