const ocllCaseSet = {
  details: {
    id: "ocll",
    title: "OCLL",
    subTitle: "2-Look OLL 2",
    mask: "oll",
    view: "plan",
    numCases: 7,
  },
  cases: [
    {
      id: "9c42e369-25cc-4165-bdee-bf1a3b5af6f7",
      name: "OLL 21",
      group: "No Corners Solved",
      prob: 2,
      scrambles: [
        "F R2 D2 B2 D' B2 D' R2 F2 U F",
        "B' R2 D2 F2 D F2 D R2 B2 U' B'",
        "B L2 F2 D2 F D2 B' L2 F2 U2 F'",
        "R B2 D2 L2 D' L2 D' B2 R2 U R",
      ],
      algs: ["R U2' R' U' R U R' U' R U' R'", "R U R' U R U' R' U R U2 R'"],
    },
    {
      id: "1b730f56-333a-4c70-8996-300ba86d0a27",
      name: "OLL 22",
      group: "No Corners Solved",
      prob: 4,
      scrambles: [
        "B' U2 B F' U L2 U L2 U' L2 F",
        "L' U2 L R' U F2 U F2 U' F2 R",
        "B U2 B' F U' R2 U' R2 U R2 F'",
        "R' U' R U' B2 R' U2 R U2 R B2 R'",
      ],
      algs: ["R U2' R2' U' R2 U' R2' U2' R"],
    },
    {
      id: "b2f24506-a6b0-4a6f-96f8-7d959d12c2e8",
      name: "OLL 26",
      group: "One Corner Solved",
      prob: 4,
      scrambles: [
        "F' U2 F U2 B U' F' U B' F U",
        "B' U2 B U2 F U' B' U B F' U",
        "R' U2 R U2 L U' R' U L' R U",
        "B2 R2 F D2 F D F' D F' R2 B2",
      ],
      algs: ["R U2' R' U' R U' R'", "R' U' R U' R' U2 R"],
    },
    {
      id: "e6547dd9-feea-46ad-b0f5-3abac69170fc",
      name: "OLL 27",
      group: "One Corner Solved",
      prob: 4,
      scrambles: [
        "B U2 B' U2 F' U B U' B' F U'",
        "L2 F2 L U' L U' L' U2 L' F2 L2",
        "L' U' L2 U L2 U L2 U2 L2 U2 L",
        "B U2 B2 U2 B2 U B2 U B2 U' B'",
      ],
      algs: ["R U R' U R U2' R'", "R' U2 R U R' U R"],
    },
    {
      id: "4178d76d-fc48-47af-ab89-76d2e4105db4",
      name: "OLL 23",
      group: "Two Corners Solved",
      prob: 4,
      scrambles: [
        "R U2 R2 F2 D2 L2 D L2 D F2 R",
        "B' U2 B2 L2 D2 F2 D' F2 D' L2 B'",
        "B U2 B2 R2 D2 F2 D F2 D R2 B",
        "F' U2 F2 R2 D2 B2 D' B2 D' R2 F'",
      ],
      algs: ["R2 D R' U2 R D' R' U2 R'", "R2 D' R U2 R' D R U2 R"],
    },
    {
      id: "250a4995-086c-4bbb-b01e-8a7afe763230",
      name: "OLL 24",
      group: "Two Corners Solved",
      prob: 4,
      scrambles: [
        "R U R' U' R' F2 R F2 L' U2 L",
        "B' U' B U F U2 F' L2 B L2 B'",
        "L' U' L U R U2 R' F2 L F2 L'",
        "F' U' F U F R2 F' R2 B U2 B'",
      ],
      algs: ["r U R' U' r' F R F'", "R U R D R' U' R D' R2"],
    },
    {
      id: "657c744a-12b7-439e-90d4-fea0e0843b09",
      name: "OLL 25",
      group: "Two Corners Solved",
      prob: 4,
      scrambles: [
        "B L2 B' L2 F U2 F' U' B' U B",
        "B' R2 B R2 F' U2 F U B U' B'",
        "L' B2 L B2 R' U2 R U L U' L'",
        "B' U2 B L2 F' L2 F U F U' F'",
      ],
      algs: ["x R' U R D' R' U' R D x'", "R' F R B' R' F' R B"],
    },
  ],
};
export default ocllCaseSet;
