const f2l1CaseSet = {
  details: {
    id: "f2l1",
    title: "F2L 1",
    subTitle: "Last Slot",
    mask: "f2l",
    view: "",
    numCases: 41,
  },
  cases: [
    {
      id: "d1a91710-6247-4591-a405-f16bec6e3b15",
      name: "F2L 1",
      algs: ["R U R'"],
      group: "Unpaired A",
      scrambles: [
        "U' R U B U B' U' R' U2",
        "R2 B2 F2 L' D' L B2 F2 R2",
        "R U' F2 D2 B2 L B2 D2 F2",
        "U2 F R' F' R U2 R U R'",
      ],
    },
    {
      id: "16cdd2ef-e700-40b2-9858-291b2e3e6b7c",
      name: "F2L 2",
      algs: ["U' R U R' U R U R'"],
      group: "Unpaired A",
      scrambles: [
        "R2 U2 R2 F R F' R U2 R2",
        "U' F2 R2 L' U' L U R2 F2",
        "R U' R' F' L F' L' F2 U'",
        "R U2 F U R U' R' F' R'",
      ],
    },
    {
      id: "96ea4e41-448a-456d-8244-23dd3fc4bb6a",
      name: "F2L 3",
      algs: ["U' R U R' U2 R U' R'"],
      group: "Unpaired A",
      scrambles: [
        "U' F' B' R' U' R U F B",
        "U F' B' R' U' R U F B",
        "U F2 L F2 L' U' L' U L",
        "D' F U' L F L' U F' D",
      ],
    },
    {
      id: "830121be-73b5-444f-ba1b-1d289d43347c",
      name: "F2L 4",
      algs: ["U' R U2 R' U2 R U' R'"],
      group: "Unpaired A",
      scrambles: [
        "R F R U R' U' F' R' U'",
        "U2 R U2 B U2 B' U' R' U'",
        "F2 R2 U2 R' F2 R U2 R2 F2",
        "B2 L2 F' L' F' L F2 L2 B2",
      ],
    },
    {
      id: "1d1a4d5f-ebc5-4085-8883-8c6ed4d96fc1",
      name: "F2L 5",
      algs: ["U R U2 R' U R U' R'"],
      group: "Unpaired A",
      scrambles: [
        "F' U2 L' U' B L' B' L2 F",
        "U' R U2 R' U2 R U2 R' U'",
        "U' R B U2 B2 R B R2 U",
        "R U2 B' R' B U B U' B'",
      ],
    },
    {
      id: "39c24301-9dc0-4f5a-876e-5c237681460d",
      name: "F2L 6",
      algs: ["U2 R U R' U R U' R'"],
      group: "Unpaired A",
      scrambles: [
        "B2 R D' B U2 B' D R' B2",
        "U F2 L F L2 U2 L F U2",
        "L R' D' F2 D L' R2 U R'",
        "U' R B L U' L' U' B' R'",
      ],
    },
    {
      id: "9672d638-4a49-40d5-bc5d-b093160d639e",
      name: "F2L 7",
      algs: ["y L' U' L y'"],
      group: "Unpaired B",
      scrambles: [
        "F2 D' F U2 F' D F U' F",
        "B' F' U2 F U F' U2 B F",
        "B U L U' L' B' F' U F",
        "U B' R B U' B' R' B U2",
      ],
    },
    {
      id: "9edbf49b-6d49-42c0-9a46-c3934a02faf3",
      name: "F2L 8",
      algs: ["y U L' U' L U' L' U' L y'"],
      group: "Unpaired B",
      scrambles: [
        "F2 U2 F2 R' F' R F' U2 F2",
        "F' U F R' F R F2 U F",
        "U2 F' U F U F' U F U'",
        "F2 U' F R U R' F' U F2",
      ],
    },
    {
      id: "cdb2144d-ebf0-47eb-8430-c3048372f753",
      name: "F2L 9",
      algs: ["y U L' U' L U2 L' U L y'"],
      group: "Unpaired B",
      scrambles: [
        "R' F' U' F2 R F' R' U R",
        "R B' F R B R' F' U' R'",
        "R U' R' U2 R' F R F' U2",
        "U' R2 U F R2 B F' U B' R2",
      ],
    },
    {
      id: "dbf97ea5-561f-4f25-9e43-2d689b49365a",
      name: "F2L 10",
      algs: ["y U L' U2 L U2 L' U L y'"],
      group: "Unpaired B",
      scrambles: [
        "L2 B2 R' F D' F' R B2 L2 U'",
        "R' B' R2 B R U R2 B' R2 B",
        "R2 U' R2 U R2 U R2 B' R2 B",
        "U B' U' B U B' R2 B' R2 B2",
      ],
    },
    {
      id: "ec0a8259-e92b-44f4-90c5-5b007d1b154f",
      name: "F2L 11",
      algs: ["y U' L' U2 L U' L' U L y'"],
      group: "Unpaired B",
      scrambles: [
        "R' F2 R U' F' U R' F2 R",
        "F R2 B' D2 B' D2 B2 R2 F' U",
        "U' F' U' L F R' F2 L' F2 R",
        "U F' U2 L F L' U' L' U L",
      ],
    },
    {
      id: "3d82365b-7c80-4009-bba2-6569558c4151",
      name: "F2L 12",
      algs: ["y U2 L' U' L U' L' U L y'"],
      group: "Unpaired B",
      scrambles: [
        "R U2 B U L U' L' B' R'",
        "F U2 F' U2 F' U2 L F L'",
        "F' U2 R' F' U' F U R F",
        "F' U2 R' U L' U' L R F",
      ],
    },
    {
      id: "07902ef1-eb28-4532-8b22-06fe2e7db4cc",
      name: "F2L 13",
      algs: ["U R U' R'"],
      group: "Paired A",
      scrambles: [
        "R2 F2 R' U R' U' R2 F2 R2",
        "R2 F2 R' U2 R' U2 R2 F2 R2",
        "F2 R' B2 R F' R' B2 F' R",
        "U2 R U R' U F' U F U",
      ],
    },
    {
      id: "fbab28d2-be84-4e21-8b2a-3c521d8c5982",
      name: "F2L 14",
      algs: ["U' R U' R' U R U R'"],
      group: "Paired A",
      scrambles: [
        "R2 U B' U' R' U B U2 R'",
        "B2 R' B R' B' R2 B R' B",
        "U2 R U' R2 F R F2 U F",
        "R' U2 R' U2 R U2 B' R2 B",
      ],
    },
    {
      id: "2587ebd5-2f83-40c2-874c-ee784c7423b9",
      name: "F2L 15",
      algs: ["R U2 R' U' R U R'"],
      group: "Paired A",
      scrambles: [
        "U' R2 L' B' R' B L U2 R'",
        "F' U2 R U B U' B' R' F",
        "U' F' U2 R U B U' B' R' F",
        "R U' R2 F R F' U R U' R'",
      ],
    },
    {
      id: "6631788d-e217-4669-b579-10ea10233379",
      name: "F2L 16",
      algs: ["U R U' R' U' R U' R' U R U' R'"],
      group: "Paired A",
      scrambles: [
        "U F2 L F L2 U L F U2",
        "F2 L' U' L U F U F U2",
        "F' U2 L' U2 L F R U R'",
        "U R U2 B' R B R' U' R'",
      ],
    },
    {
      id: "7b12c1e6-bafb-42c4-b0c4-438515b28b53",
      name: "F2L 17",
      algs: ["U R' F R F' U R U R'"],
      group: "Paired A",
      scrambles: [
        "F' U2 F U' F' L F' L' F2",
        "R B U2 B' R' F' U F U2",
        "R B U2 L U' L' B' U' R'",
        "L' R B L U L' B' L R'",
      ],
    },
    {
      id: "bf519ca8-e409-4ce9-a255-db6ff2b853d0",
      name: "F2L 18",
      algs: ["R' U2 R2 U R2' U R"],
      group: "Paired A",
      scrambles: [
        "R' U2 R U R U' R2 U2 R",
        "F2 D2 B2 L' B2 D2 F2 U' R'",
        "D R2 U' R' U' R U2 R2 D'",
        "R U2 R' U R U2 B' R B R2",
      ],
    },
    {
      id: "713c2ce7-3ed6-404c-9800-c86f897cae3d",
      name: "F2L 19",
      algs: ["y U' L' U L y'"],
      group: "Paired B",
      scrambles: [
        "R B2 L' B' L U' B' R' U",
        "R2 U R B' R' B U' R2 U'",
        "F' L' B L' B' L2 U2 F U2",
        "U2 F' U2 B U' F U B' U2",
      ],
    },
    {
      id: "8fb6b096-c9a8-4cd4-8ef7-e1cf01f89dcc",
      name: "F2L 20",
      algs: ["y U L' U L U' L' U' L y'"],
      group: "Paired B",
      scrambles: [
        "U2 F2 L F2 U F' U' L' F",
        "R' B2 U2 B' R' B U2 B2 R",
        "U' F' U2 L2 B L B' L F",
        "F' U F2 R U R' U' F' U'",
      ],
    },
    {
      id: "17ba5e50-182b-4905-99e7-62c9e2313bcd",
      name: "F2L 21",
      algs: ["y L' U2 L U L' U' L y'"],
      group: "Paired B",
      scrambles: [
        "B2 R2 B R' U2 R B' R2 B2",
        "U' F2 B L F L' B' U2 F",
        "U2 B F2 L F L' B' U2 F",
        "F R' F2 U' F U R F' U2 F",
      ],
    },
    {
      id: "f7a84d90-527a-4263-b158-b5b094f6ce6e",
      name: "F2L 22",
      algs: ["y U' L' U L U L' U L U' L' U L y'"],
      group: "Paired B",
      scrambles: [
        "U2 F2 U' F U' F' U2 F2 U2",
        "U' F2 U' F U' F' U2 F2 U2",
        "U2 R2 B' R' B2 U' B' R' U2",
        "U2 R2 B U B' U' R' U2 R' U'",
      ],
    },
    {
      id: "4b78d498-490d-4c23-8191-a93657b80e93",
      name: "F2L 23",
      algs: ["R U' R' U2 y L' U' L y'"],
      group: "Paired B",
      scrambles: [
        "U F' B' U' R' U R F B",
        "R U2 R' U R B' R B R2",
        "U F' U2 F' L F L' U F",
        "L R U2 B U B' U L' R'",
      ],
    },
    {
      id: "9d8d8563-b7f4-4a34-95da-25e1f69b9671",
      name: "F2L 24",
      algs: ["U' R U2 R' U y L' U' L y'"],
      group: "Paired B",
      scrambles: [
        "D' L D R' D' F' L' D R",
        "F' U2 F U' F' U L' U L F",
        "F' U' F U2 F' U2 L F' L' F2",
        "U2 L' U' L2 F' L' U F2 U2 F'",
      ],
    },
    {
      id: "209dfee9-2a64-49e8-a159-fa71770f9980",
      name: "F2L 25",
      algs: ["U R U R' U2 R U R'"],
      group: "Edge in Slot",
      scrambles: [
        "D U2 B2 D' F D B2 D' F'",
        "U2 R U' R' U2 R U' R' U'",
        "U R U B U B' U2 R' U'",
        "B' R' B' R' B R' B' R2 B2",
      ],
    },
    {
      id: "7bc6e109-0a8c-4fdd-80d2-292ba4d4bad7",
      name: "F2L 26",
      algs: ["U' R U' R' U2 R U' R'"],
      group: "Edge in Slot",
      scrambles: [
        "R B2 F' D L D' B2 F R'",
        "F' U2 L F' L' F U2 F U2",
        "F R L2 B' R B R2 L2 F'",
        "B R U L U' L' R' B' U'",
      ],
    },
    {
      id: "9a02c853-369f-4fe8-b7f4-446fa8da46cb",
      name: "F2L 27",
      algs: ["U R U' R' U R U' R' U R U' R'"],
      group: "Edge in Slot",
      scrambles: [
        "R U2 R B' R' B U2 R' U2",
        "R2 U2 R2 U2 R' F R2 F' R'",
        "B' F' R2 F2 R F2 R B F",
        "B D2 U L U' L' D2 B' U",
      ],
    },
    {
      id: "e7559eb4-b55a-4db9-9fe9-bc58b41abd07",
      name: "F2L 28",
      algs: ["U F' U' F U' R U R'"],
      group: "Edge in Slot",
      scrambles: [
        "U' R U R' U' F' U F U2",
        "U' R U2 R' U2 F' U F U2",
        "U2 F' U F' L F L' F U'",
        "F' U2 F' L' U' L U F2 U",
      ],
    },
    {
      id: "6a740159-66a7-40f9-8151-e20dd6ed75f4",
      name: "F2L 29",
      algs: ["U' R U R' U y L' U' L y'"],
      group: "Edge in Slot",
      scrambles: [
        "U2 R' F R F' R U' R' U2",
        "R U' R' U F' L' U L F",
        "U2 F' U2 F U2 R U' R' U2",
        "U F' U' F U R U' R' U2",
      ],
    },
    {
      id: "74f91479-6028-424c-a37d-710362bff3f7",
      name: "F2L 30",
      algs: ["U' R' F R F' R U' R'"],
      group: "Edge in Slot",
      scrambles: [
        "R U2 R' F' L' U L U' F",
        "U F R' F' R U' F' U2 F",
        "U' F' U' F R B' R B R2",
        "U R U B U2 B2 R B R2",
      ],
    },
    {
      id: "62202439-81e3-4298-bdfb-da56cfdca373",
      name: "F2L 31",
      algs: ["R U R' U' R U R'"],
      group: "Corner in Slot",
      scrambles: [
        "U2 L' R2 B' R' B L U' R'",
        "U R2 B' R F R2 B R' F'",
        "F2 L F L2 U' L U2 F U",
        "R U2 B U' B2 R B R2 U'",
      ],
    },
    {
      id: "6fc677ba-420d-4a10-856a-889fc53cc28e",
      name: "F2L 32",
      algs: ["R U' R' U R U' R'"],
      group: "Corner in Slot",
      scrambles: [
        "F' L F' L' F2 U2 R U R'",
        "F2 L2 B L B' L F U2 F",
        "U F R2 B' R F' R' B R2",
        "F' U2 F U2 F' L' U2 L F",
      ],
    },
    {
      id: "5b4dd3b0-2426-4a4e-8a9d-1459361d91b0",
      name: "F2L 33",
      algs: ["U' R' F R F' R U R'"],
      group: "Corner in Slot",
      scrambles: [
        "U' R2 U B' R' B R U' R2",
        "U' B' R B' R' D' R D B2",
        "D U2 F D' R' D F' D' R",
        "F' U L U F U' F2 L' F2",
      ],
    },
    {
      id: "c8ad3eff-c58f-48c9-b9d6-3aa3dff7aa23",
      name: "F2L 34",
      algs: ["y L' U' L U L' U' L y'"],
      group: "Corner in Slot",
      scrambles: [
        "F' U2 L' U L2 F' L' F2 U",
        "R2 B' R' B2 U2 B' U2 R' U2",
        "R2 B' R' B2 U B' U2 R' U'",
        "U' F2 B L F L' B' U F",
      ],
    },
    {
      id: "1120354a-6952-4120-82b1-cf8710327030",
      name: "F2L 35",
      algs: ["y L' U L U' L' U L y'"],
      group: "Corner in Slot",
      scrambles: [
        "F R2 B' R F' R2 B U2 R'",
        "U F' U2 F2 R' F' R2 U2 R'",
        "U F' U2 F' L F L' F U2",
        "U2 R U2 R' F' L F' L' F2",
      ],
    },
    {
      id: "8a7c2cfc-4e09-4040-9544-2eead17b21c0",
      name: "F2L 36",
      algs: ["U R U' R' U' y L' U L y'"],
      group: "Corner in Slot",
      scrambles: [
        "U' B' D' R' D B R' U R",
        "U' F' R2 D2 L2 B' L2 D2 R2",
        "U' F2 U' L F L' F' U F2",
        "U2 F2 U' L F L' F' U F2",
      ],
    },
    {
      id: "ad6049d0-8df4-4ef3-a11b-5e684695935b",
      name: "F2L 37",
      algs: ["R U' R' U R U2 R' U R U' R'"],
      group: "Both in Slot",
      scrambles: [
        "F' U2 F' U' F U' F' U2 F2 U",
        "F2 U2 F R2 B' R' B R' F U",
        "R2 U2 R' U' R U' R' U2 R' U'",
        "F' U2 L' U L U' L' U2 L F",
      ],
    },
    {
      id: "9f0de498-80ba-49fc-b300-77439639d8e8",
      name: "F2L 38",
      algs: ["R U' R' U' R U R' U2 R U' R'"],
      group: "Both in Slot",
      scrambles: [
        "F' L' U2 L U L' U' L U2 F",
        "R U2 R U R' U R U2 R2 U2",
        "F' L2 D' L U2 L' D L2 U2 F",
        "F' U2 F' L2 B L B' L F2 U",
      ],
    },
    {
      id: "1adf56b6-7f9b-40b6-895f-62959f5056a9",
      name: "F2L 39",
      algs: ["R U' R' r U' r' U2 r U r'"],
      group: "Both in Slot",
      scrambles: [
        "F' L' U2 L F R U R' U",
        "U F' L' U2 L F R U R'",
        "U2 F' U F R B U2 B' R'",
        "F' U F R B U' B' U' R'",
      ],
    },
    {
      id: "4de57491-489b-4e7e-8047-08715f1bc37a",
      name: "F2L 40",
      algs: ["r U' r' U2 r U r' R U R'"],
      group: "Both in Slot",
      scrambles: [
        "U2 F' U F' U' L' U L F2 U2",
        "U' R2 B' R' B2 U' B' U2 R' U2",
        "U R U' R' F' L' U2 L F U2",
        "U' R B U2 B' R' F' U' F U2",
      ],
    },
    {
      id: "ec80c9f8-ee52-4153-8cd0-869d247c8cfc",
      name: "F2L 41",
      algs: ["R U2 R' U R U2 R' U F' U' F"],
      group: "Both in Slot",
      scrambles: [
        "R2 U2 F R2 F' U2 R' U R'",
        "U F' U F' U2 R' F2 R U2 F2",
        "U' F2 U2 R' F2 R U2 F U' F",
        "U' R2 U2 F R2 F' U2 R' U R'",
      ],
    },
  ],
};

export default f2l1CaseSet;
